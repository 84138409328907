<template>
  <g>
    <svg:style>
      .cls-4, .cls-5, .cls-6 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient
      id="handle-gradient"
      :x1="doorLeftWidth1 + 9.95"
      :y1="doorTopHeight1 + 147.88"
      :x2="doorLeftWidth1 + 15.54"
      :y2="doorTopHeight1 + 147.88"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#97999d"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a7aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient
      id="inox-gradient"
      :x1="doorLeftWidth1 + 1408.84"
      :y1="doorTopHeight1 + 14092.75"
      :x2="doorLeftWidth1 + 1408.84"
      :y2="doorTopHeight1 + 14264.58"
      gradientTransform="translate(-793.63 -13913.57)"
      xlink:href="#handle-gradient"/>
    <g id="V13">
      <rect
        id="inox"
        v-if="showInox"
        class="cls-4"
        :x="inoxX"
        :y="inoxY"
        :width="inoxWidth"
        :height="inoxHeight"/>
      <rect
        id="glass"
        class="cls-5"
        :x="inoxX + 4"
        :y="inoxY + 4"
        width="13.21"
        height="54.51"/>
      <rect
        id="glass-2"
        data-name="glass"
        class="cls-6"
        :x="inoxX + 4"
        :y="inoxY + 114"
        width="13.21"
        height="54.51"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
      inoxWidth: 21.32,
      inoxHeight: 171.83,
    }
  },
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
    inoxX() {
      return this.doorLeftWidth + (this.doorWidth - this.inoxWidth)/2
    },
    inoxY() {
      return this.doorTopHeight + (this.doorHeight - this.inoxHeight)/2
    },
  },
}
</script>
